// Import Fuse main library
@import '@fuse/scss/core';
@import '@fuse/scss/fuse';

.snackBarOk {
  background-color: #7EE28F;
  color: rgb(0, 110, 0);
}

.snackBarError {
  background-color: #FD6E6A;
  color: rgb(110, 0, 0);
}

.base-layout {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  width: 100%;
  overflow-y: auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 20px;

    .button-container {
      margin-top: 0px;
    }
  }

  mat-tab-group {
    flex-grow: 1;
  }
}

.full-page-layout {
  height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
}

.number-column {
  justify-content: flex-end !important;
  padding-right: 4px;
}

mat-footer-cell.number-column, mat-header-cell.number-column {
  padding-right: 2px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  column-gap: 16px;
  row-gap: 12px;

  &.selection-buttons {
    margin-top: 0px;
    margin-bottom: 16px;
  }

  .end-button {
    margin-left: auto;
  }

  button mat-icon {
    color: currentColor;

    &:not(&:last-child) {
      margin-right: 8px;
    }
  }
}

.dialog {
  margin-bottom: 16px;

  &.full-height {
    display: flex;
    height: 100%;
    padding-bottom: 20px;
    margin-bottom: 0px;
    flex-direction: column;

    .dialog-content {
      flex-grow: 1;
      padding-bottom: 0px;

      mat-tab-group {
        flex-grow: 1;
      }
    }
  }

  .dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    font-weight: normal;

    .title-with-icon {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
  }
}

.alert-dialog {
  color: #472F92;
  border: #472F92 solid 2px;
  display: block;
  padding: 10px 10px 6px 10px;

  &.full-height {
    display: flex;
    height: 100%;
    padding-bottom: 20px;
    margin-bottom: 0px;
    flex-direction: column;

    .alert-dialog-content {
      flex-grow: 1;
      padding-bottom: 0px;

      mat-tab-group {
        flex-grow: 1;
      }
    }
  }

  .alert-dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    font-weight: normal;
  }

  .alert-dialog-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
  }

  .mat-dialog-actions {
    margin-bottom: -4px !important;
  }
}

.form-block {
  margin-top: 12px;

  & > .label {
    font-weight: 700;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    & > mat-icon {
      margin-left: 8px;
    }

    &.icon-prefix > mat-icon {
      margin-left: 0px;
      margin-right: 8px;
      color: inherit;
    }
  }

  .description {
    margin-bottom: 24px;
  }

  &.top-spaced-block {
    margin-top: 32px;
  }
}

.list-block {
  max-width: 600px;

  .i-list {
    border: 1px solid #E9E9E9;
    max-width: 340px;

    &._wide {
      max-width: unset;
    }

    mat-list {
      padding-top: 0px;
    }

    .first-line {
      display: flex;
      align-items: center;
    }

    .second-line {
      opacity: 0.5;
    }
  }
}

.layout-narrow-col {
  width: 240px;

  & > mat-icon {
    vertical-align: middle;
    margin-left: 8px;
  }
}

.grid-wizard-2c {
  display: grid;
  grid-template-columns: 380px 380px;
  column-gap: 50px;
  
  @include media-breakpoint-down('xs') {
    grid-template-columns: 1fr;
  }
}

.grid-wizard-2c mat-form-field {
  width: 100%;
}

.grid-2c {
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 1fr));
  column-gap: 40px;

  &.small-gap {
    column-gap: 16px;
  }

  &.mode-3c {
    grid-template-columns: repeat(3, minmax(140px, 1fr));
  }

  &.mode-2c {
    grid-template-columns: 240px 240px auto;
  }

  &.mode-2cf {
    grid-template-columns: 1fr 240px auto;
  }

  @include media-breakpoint-down('xs') {
    grid-template-columns: 1fr;

    &.mode-3c {
      grid-template-columns: 1fr;
    }

    &.mode-2c {
      grid-template-columns: 1fr;
    }
  }
}

.grid-4c {
  display: grid;
  grid-template-columns: 250px 200px 200px 200px;
  column-gap: 20px;
}

.grid-label-column {
  text-align: end;
  display: inline-block;
  font-weight: bold;
  vertical-align: middle; 
  margin-right: 20px;
}

.table-list {
  min-width: 768px;

  mat-header-row {
    background-color: #F4F4F4;
  }

  &.clickable {
    mat-row {
      cursor: pointer;

      &:hover {
        background-color: #dadada;
      }
    }
  }

  .inactive-row {
    mat-cell:not(.mat-column-checkbox) {
      opacity: 0.4;
    }
  }

  .mat-column-checkbox {
    max-width: 60px;
  }

  .mat-column-number:not(.default-width),
  .mat-column-id:not(.default-width) {
    max-width: 168px;
    min-width: 104px;

    &:first-child {
      min-width: 112px;
    }
  }

  .mat-column-status {
    min-width: 108px;
    max-width: 180px;

    & > mat-icon {
      margin-right: 8px;
    }
  }

  .mat-table-col-time {
    max-width: 100px;
  }

  .mat-table-col-xs {
    max-width: 80px;
  }

  .mat-table-col-s {
    max-width: 120px;
  }

  .mat-table-col-m {
    max-width: 240px;
  }

  .mat-table-col-l {
    max-width: 360px;
  }

  .main-column {
    flex-grow: 3;
  }

  mat-cell.link-cell {
    align-self: stretch;
    align-items: stretch;
    border: 1px solid transparent;
    border-radius: 8px;

    &.active:hover {
      border-color: #049BE5;
    }

    a {
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 0px 8px;
      word-break: break-word;

      &:not(.mat-button):not(.mat-raised-button):not(.mat-icon-button):hover {
        text-decoration: none;
      }
    }

    .link-plug {
      display: flex;
      align-items: center;
      padding: 0px 8px;
    }
  }
}

.input-with-info {
  display: flex;

  & > :first-child {
    width: 100px;
    flex-grow: 1;
  }

  .info-wrapper {
    height: 100%;
    padding-top: 16px;
    margin-left: 8px;
  }

  & > button {
    margin-top: 12px;
  }
}

.input-with-button {
  display: flex;
  align-items: baseline;

  & > :first-child {
    flex-basis: 400px;
  }

  button {
    margin-left: 16px
  }

  @include media-breakpoint-down('xs') {
    flex-direction: column;
    align-items: stretch;

    & > :first-child {
      flex-basis: 0px;
    }

    button {
      margin-left: 0px
    }
  }
}

.checkbox-with-info {
  margin-bottom: 16px;

  & > mat-icon {
    vertical-align: middle;
    margin-left: 8px;
  }
}

.inline-button {
  display: inline-block;
}

.image {
  margin-bottom: 20px;
  position: relative;
  width: fit-content;

  &.circled {
    overflow: hidden;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  &.photo {
    height: 208px;
    width: 208px;
  }

  &.thumbnail {
    height: 72px;
    width: 72px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      border-radius: 4px;
    }

    & button.mat-mini-fab.delete-button {
      position: absolute;
      top: -0.5em;
      right: -0.5em;
      font-size: 24px;
      width: 1em;
      height: 1em;
      background-color: white;

      & .mat-button-wrapper {
        display: block;
        height: 1em;
        padding: 0;
        transform: scale(0.8);
      }
    }
  }

  img {
    object-fit: contain;
    height: 100%;
  }

  .upload-button {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    cursor: pointer;
    transition: opacity 600ms cubic-bezier(0.25, 0.8, 0.25, 1);

    &.mock-text {
      font-size: 18px;
      padding: 0px 8px;
      text-align: center;
      color: white;
      background-color: rgba(0, 0, 0, 0.8);
      cursor: unset;
    }

    &:hover {
      opacity: 1
    }

    mat-icon {
      color: white;
      opacity: 0.9;
      transform: scale(2);
    }
  }
}

.image-gallery {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  .group-divider {
    background-color: grey;
    width: 4px;
    border-radius: 4px;
  }
}

.text-with-spinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.breadcrumbs {
  margin-bottom: 20px;

  .separator {
    padding-left: 8px;
    padding-right: 8px;
    color: #9B9B9B;
  }

  .back-button {
    display: inline-flex;
    align-items: baseline;
    cursor: pointer;

    mat-icon {
      color: black;
      font-size: 12px;
      min-width: 0px;
      min-height: 0px;
      height: 1em;
      width: 1em;
      margin-right: 4px;
    }
  }
}

.breadcrumbs-and-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .button-container {
    margin-top: 0px;
    margin-bottom: 4px;
  }
}

.search-group {
  display: flex;
  align-items: baseline;
  column-gap: 20px;
  flex-wrap: wrap;

  & > mat-checkbox {
    margin-bottom: 24px;
    margin-top: 12px;
  }

  & > button {
    margin-bottom: 24px;
  }
}

.search-field {
  width: 400px;
  max-width: 100%;
}

.double-date-picker {
  width: 320px;
  position: relative;

  .fake {
    position: absolute;
    width: 100%;
    top: 0.25em;

    .mat-form-field-flex {
      height: 4em;
    }
  }

  .inputs-wrapper {
    display: flex;
    column-gap: 14px;
    padding-left: 14px;
    padding-right: 8px;
    padding-top: 0px;
    align-items: center;
    // height: 70px;

    mat-form-field {
      width: 100px;
      flex-grow: 1;

      mat-datepicker-toggle mat-icon {
        display: inline;
      }
    }
  }

  &.with-presets {
    width: 300px;

    .preset-dropdown {
      width: 32px;
      height: 32px;
      line-height: 32px;
      margin: -8px -8px 0px -8px;
    }
  }
}

.text-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 4px;
  line-height: 1.2em;
  border-radius: 5px;
  flex-grow: 0.5;
  width: 80px;
  text-align: center;
  background-color: #eeeeee;
}

.not-shown {
  display: none !important;
}

.flex-list-row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 8px;
  margin-bottom: 20px;
}

.info-row {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 4px;

  & > div {
    display: inline;
  }

  .label {
    color: #9B9B9B;
  }
}

.server-message-container {
  display: flex;
  flex-direction: column;
  overflow: auto;

  mat-progress-bar {
    flex-shrink: 0;
  }

  .terminal-wrapper {
    overflow: auto;
    width: 100%;
    margin-top: 16px;

    .message {
      white-space: break-spaces;

      &.error {
        color: red;
      }
    }
  }
}

.map-wrap {
  display: flex;
  height: 100%;

  &.spaced {
    padding-top: 32px;
  }

  & > div {
    flex: 1 0 200px;
  }

  .map-plug {
    padding: 24px;
  }
}

.flex-column-autoheight {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.scrollable {
  overflow: auto
}

.faded-text {
  opacity: 0.54;
  font-weight: 500;
}

.details-header {
  padding: 8px 0px;
  cursor: pointer;
}

// #region maps

mat-icon.dashboard-icon,
.dashboard-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 28px;
  height: 1em;
  min-height: 1em;
  width: 1em;
  min-width: 1em;
  color: white;

  &.map {
    border: 2px solid white;
  }

  &.order {
    background-color: #7B61FF;
    &.live-map {
      background-color: #7b61ff;
    }

    //#region Colors of order types - class names should be synced with ECustomIcons (at 'app/consts/enums.ts')
    &.pickup {
      background-color: #914DE8;
    }

    &.delivery {
      background-color: #634ECC;
    }

    &.pickup_delivery_d_node,
    &.pickup_delivery_p_node,
    &.pickup_delivery {
      background-color: #7B61FF;
    }

    &.service {
      background-color: #645A94;
    }

    // additional class for order's images location
    &.image {
      background-color: #FE9526;
    }

    //#endregion Colors of order types

    &[selected] {
      box-shadow: 0px 0px 5px 5px #7b61ff80;
    }

    &.live-map[selected] {
      box-shadow: 0 0 5px 5px #7b61ff;
    }
  }

  &.vehicle {
    background-color: #FE9526;

    &.inactive {
      background-color: #9B9B9B;
    }
  }
}

.vehicle-pane.selected {
  z-index: 624;

  .dashboard-icon.map.vehicle {
    box-shadow: 0px 0px 0px 1px #383838;
  }
}

.dashboard-dot {
  height: 18px;
  min-height: 18px;
  width: 18px;
  min-width: 18px;
  background-color: currentColor;
  border-radius: 50%;
  border: 2px white solid;
  display: flex;
  align-items: center;
  justify-content: center;

  &.thick-border {
    border-width: 3px;
  }

  span {
    color: white;
    font-size: 9px;
    font-weight: 600;
    line-height: 1em;
  }

  &._mat-icon-sized {
    height: 24px;
    min-height: 24px;
    width: 24px;
    min-width: 24px;

    span {
      font-size: 12px;
    }
  }
}

.route-outer-line {
  display: none;
}

.manifest-pane {
  &.selected {
    z-index: 622;

    .dashboard-dot {
      box-shadow: 0px 0px 0px 1px #383838;
    }

    .route-outer-line {
      display: inline;
    }
  }

  &._hover {
    z-index: 633;
  }

  &:hover {
    z-index: 634;
  }

  &._hide {
    display: none;
  }
}

// #endregion maps

.split-screen {
  display: flex;
  flex-grow: 1;

  & > * {
    flex: 0 0 50%;
    width: 50%;
  }

  & > :first-child {
    padding-right: 24px;
  }
}

.grey {
  color: #9B9B9B;
}

.orange {
  color: #F1AB50;
}

.green {
  color: #7CBA73;
}

.red {
  color: #DB5F59;
}

.violet {
  color: #4A148C;
}

.lavender-blue {
  color: #7B61FF;
}

.blue {
  color: #55BADE;
}

.loadIdentifier
{
  font-size: smaller;
  color: grey;
  padding-left: 4px;  
}

mat-icon.file-type-icon {
  min-height: unset;
  min-width: unset;
  width: 1.1em;
  color: inherit;
  font-size: 1em;
  height: 2em;
  line-height: 1em;
  margin-right: 0.4em;
  padding-bottom: 0.5em;
  padding-top: 0.3em;
}

.color-dot {
  display: inline-block;
  flex-shrink: 0;
  background-color: currentColor;
  width: 13px;
  height: 13px;
  margin-right: 7px;
  border-radius: 100%;

  &.small {
    width: 5px;
    height: 5px;
  }
}

.outer-link {
  display: inline-flex;
  align-items: center;
  font-weight: 500;

  span {
    text-decoration: underline;
  }

  &:not(.mat-button):not(.mat-raised-button):not(.mat-icon-button):hover {
    text-decoration: none;

    span {
      text-decoration: none;
    }
  }

  mat-icon {
    font-size: 16px;
    margin-left: 8px;
  }
}

.intro-view {
  width: 100%;
  height: 100%;
  display: flex;

  .loading-bar {
    position: fixed;
    height: 8px;
  }

  .intro-form-wrapper {
    flex-grow: 1;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 32px;
  }

  .form {
    padding: 132px 48px 48px 48px;
    width: calc(min(596px, 100%));
    margin-left: calc(min(80px, max(0px, 100% - 876px)));

    .logo {
      display: none;
      width: 128px;
      height: 128px;
      margin: 32px auto;
      border-radius: 2px;
      background: #061648;
      align-items: center;
      padding: 8px 8px 20px 8px;
    }

    .form-inputs,
    form {
      width: 100%;
      display: flex;
      flex-direction: column;

      mat-form-field .multi-suffix {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        column-gap: 4px;
      }
    }

    .submit-buttons {
      margin-top: 24px;
      display: inline-flex;
      row-gap: 16px;
      column-gap: 16px;
    }

    .input-field-errors {
      margin-top: -24px;
      min-height: 24px;
      width: 100%;
      font-weight: 400;
      font-size: 75%;

      .input-errors-wrapper {
        .input-errors {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: start;
          padding: 0.6666666667em 1em 0.3333333333em 1em;
          line-height: 125%;
        }
      }

      @include media-breakpoint-up('lg') {
        position: relative;
        margin-bottom: 0px;
        font-size: 13px;

        .input-errors-wrapper {
          position: absolute;
          left: calc(100% + 24px);
          width: 300px;
          height: 52px;
          top: -50px;
          display: flex;
          align-items: center;
          overflow: visible;

          .input-errors {
            background: #383838e6;
            padding: 12px;
            border-radius: 7px;
            color: white;
          }

          &::after {
            content: '';
            position: absolute;
            left: -12px;
            top: 50%;
            margin-top: -6px;
            border: 6px solid transparent;
            border-right-color: #383838e6;
            background: transparent;
          }
        }
      }
    }
  }

  @include media-breakpoint-down('sm') {
    fuse-app-intro-sidebar {
      display: none;
    }

    .intro-form-wrapper {
      .form {
        text-align: center;
        width: calc(min(500px, 80%));
        margin: auto;
        padding: 48px 0px;

        .logo {
          display: flex;
        }

        .submit-buttons {
          width: 80%;
          flex-direction: column;
          align-self: center;
        }
      }
    }
  }
}

// #region material fixes
.mat-tooltip {
  font-size: 14px;
}

.mat-tooltip-wrapped {
  white-space: pre-line;
}

input.mat-input-element {
  font-weight: 500;
}

mat-select {
  font-weight: 500;
}

.mat-tab-label {
  text-transform: uppercase;
}

.mat-dialog-content[mat-dialog-content] {
  max-height: unset;

  &.text {
    white-space: pre-line;
  }
}

textarea.mat-input-element.cdk-textarea-autosize {
  display: inline-table;
}

mat-header-row,
mat-row {
  column-gap: 8px
}

.mat-pseudo-checkbox-checked:after {
  width: 8px !important;
  height: 3px !important;
}

mat-chip.mat-chip {
  overflow-wrap: anywhere;
  height: auto;

  mat-icon {
    min-width: 18px;
    min-height: 18px;
  }
}

mat-icon.text-color {
  color: currentColor;
}

// Increases checkboxes
div.mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}

// makes disabled controls darker 
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
.mat-select-disabled .mat-select-value,
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.6;
}

mat-accordion.flat {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .mat-expansion-panel {
    background-color: unset;

    &:first-of-type,
    &:last-of-type {
      border-radius: 0px;
    }
  }

  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  .mat-expansion-panel-body,
  .mat-expansion-panel-header {
    padding: 0 0 0 0;
  }

  .mat-expansion-panel-header {
    height: unset !important;
  }

  .mat-content {
    justify-content: space-between;
  }
}

mat-icon.mat-icon.mat-icon-font-linked {
  color: currentColor;
  line-height: 1em;
  height: 1em;
  min-height: 1em;
  width: 1em;
  min-width: 1em;
}

// snackbars new line support
.mat-snack-bar-container {
  white-space: break-spaces;
}

.suffix-fullsized-buttons-wrapper {
  display: flex;
  column-gap: 8px;
  position: relative;
  top: -0.5em;
}

mat-slide-toggle.customized {
  .mat-slide-toggle-bar {
    width: 24px;
    background-color: #808080;

    .mat-slide-toggle-thumb-container {
      top: 2px;
      left: 2px;

      .mat-slide-toggle-thumb {
        height: 10px;
        width: 10px;
        background-color: white;
      }
    }
  }

  .mat-ripple {
    display: none;
  }

  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: #8D79F3;

      .mat-slide-toggle-thumb-container {
        transform: translate3d(10px, 0, 0);
      }
    }
  }
}

.mat-form-field.dense .mat-form-field-wrapper {
  margin: 0px;
  padding: 0px;
}

// #endregion material fixes

// #region leaflet fixes
.leaflet-tooltip.dark {
  background-color: #383838;
  border-color: #383838;
  color: white;
  padding: 12px;
  font-family: "Gilroy", "Roboto", "Helvetica Neue", "Arial", sans-serif;

  &.leaflet-tooltip-top::before {
    border-top-color: #383838;
  }
}

.grey-styled.leaflet-control-zoom.leaflet-bar a {
  color: #ffffff;
  background-color: #383838d9;
  border-bottom-color: #9B9B9B;

  &:hover {
    color: #ffffff;
    background-color: #383838d9;
    border-bottom-color: #9B9B9B;
    text-decoration: none;
  }
}

.dashboard-tooltip {
  .label-row {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    font-size: 13px;
  }

  .details {
    font-size: 12px;
    font-weight: 200;
  }

  &.vehicle-tooltip {
    .detalis {
      padding-left: 12px;
    }
  }
}

.layer-selector {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .layer-select-button {
    width: 40px;
    height: 40px;
    border: 1px solid white;
    border-radius: 4px;
    cursor: pointer;

    &.active {
      cursor: unset;
      border-color: #7B61FF;
    }
  }
}

// #endregion leaflet fixes

// #region Welcome tour
.tour-step.mat-menu-panel {
  max-width: 480px;
  margin: 8px;

  .mat-card {
    background-color: #7B61FF;
    color: white;
    padding: 20px;

    .title-text {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .mat-card-content {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .mat-card-actions {
      margin: 0px;
      display: flex;
      align-items: flex-end;

      button {
        background-color: white;
        color: #383838;
      }

      .tour-end {
        margin-left: auto;
        text-decoration: underline;
        cursor: pointer;
        user-select: none;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

// #endregion Welcome tour

// #region safety
.safety-status {
  display: flex;
  position: relative;
  padding: 4px;
  margin-right: 8px;
  border-radius: 5px;
  
  .mat-icon {
    color: currentColor;
    font-size: 16px;
    line-height: 1em;
    height: 1em;
    min-height: 1em;
    width: 1em;
    min-width: 1em;
  }

  .status-background {
    background-color: currentColor;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.15;
    border-radius: 5px;
  }
}

// #endregion safety

.checkbox-table {
  font-size: 16px;

  &__th:first-child {
    width: 350px;
  }
  &__th {
    width: 150px;
  }
  &__wrap-content {
    display: flex;
    align-items: center;
    min-height: 20px;
    .mat-icon {
      color: rgba(0, 0, 0, 0.87);
      margin-right: 4px;
    }
  }

  tr {
    height: 40px;
  }
}

.form-full-column {
  .mat-form-field {
    width: 100%;
  }
}

[hidden] {
  display: none !important;
}

.pac-container {
  margin-top: -215px;
}

.display-inherit {
  display: inherit;
}

.image-wrapper__icon {
  position: absolute;
  top: calc(50% - 19px);
  left: calc(50% - 19px);
  font-size: 38px;
  height: 38px;
  width: 38px;
  min-width: 38px;
  min-height: 38px;
}

.titled-form-block-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  .mat-icon {
    margin-right: 4px;
  }
}
.titled-form-block-body {
  margin-top: 20px;
  &__item {
    display: inline-block;
    margin-right: 12px;
    font-weight: 600;
  }
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.outline {
  text-decoration: underline;
}

.flex-block {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.pl-10 {
  padding-left: 10px;
}

.status-selection {
  display: flex;
  align-items: center;
  padding-left: 32px;

  mat-icon {
    position: absolute;
    top: -4px;
    left: 0;
  }
}

.details-text-row {
  display: flex;
  column-gap: 8px;
  font-size: 12px;

  mat-icon {
    height: 12px;
    font-size: 12px;
    width: 12px;
    min-width: 12px;
    min-height: 12px;
    padding-top: 2px;
    align-self: baseline;
  }
}

.timeline-item,
.unassigned-orders-drop-zone,
.unassigned-orders-section {
  .cdk-drag-placeholder {
    display: none;
  }
}

.underline {
  text-decoration: underline;
}

.pipe-tooltip-panel .mat-dialog-container {
  padding: 16px;
}

.link-button, .mat-button.link-button {
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0;
  font-size: inherit;
  min-width: auto;
  line-height: inherit;
}

.link-button:hover, .mat-button.link-button:hover {
  text-decoration: none;
}

.link-button:focus, .mat-button.link-button:focus {
  outline: none;
}

.timepicker-overlay {
  z-index: 1000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1000 !important;
}

.cursor-scroll {
  cursor: col-resize;
}

.cursor-grabbing {
  cursor: grabbing;
}

.grabbing-on-all-elements * {
  cursor: grabbing !important;
}

.validation-error-text {
  position: absolute;
  bottom: -20px;
  font-size: 12px;
  color: #f44336;
}

.scrollable-table-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .scrollable-table {
    overflow: auto;

    mat-header-row {
      background-color: #F4F4F4;
    }

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
  }
}
