@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-UltraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-UltraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-Heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  src: url('./Radomir\ Tinkov\ -\ Gilroy-HeavyItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}
